@import "../../constants.css";

#menu {
  width: 8vw;
  background-color: var(--navy);
  z-index: 9998;
  display: flex;
  flex-direction: column;
  justify-content: flex-start
}

@media (max-width: 1000px) {
  #menu {
    display: none
  }

  #menu * {
    display: none
  }
}

#menu a {
  color: #fff;
  font-size: .8rem;
  font-weight: 400;
  font-family: sans-serif;
  background-color: transparent;
  border: none;
  cursor: pointer;
  box-shadow: none;
  width: 100%;
  border-radius: 0;
  height: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none
}

#menu a:hover {
  font-weight: 400;
  transform: translateY(-1px);
  background-color: #ffffff0d
}

.active-menu-item {
  color: var(--pink) !important;
  border-left: 4px solid var(--pink) !important;
  transform: translateY(1px);
  background-color: #ffffff0d !important;
  pointer-events: none !important
}

#menu a:last-of-type {
  margin-top: auto !important
}