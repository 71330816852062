@import "./constants.css";
@import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/bootstrap5.css";

html {
  box-sizing: border-box
}

*,
:before,
:after {
  box-sizing: inherit
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: var(--off-white);
  color: var(--dark-grey);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
}

code,
pre {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace
}

html,
body {
  height: 100%;
  line-height: 1.5;
  margin: 0
}

h1,
h2,
h3,
h4 {
  color: var(--black);
  font-weight: 700 !important
}

textarea,
.text {
  background-color: #fff;
  border: none;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  padding: .5rem .75rem
}

button {
  background-color: #fff;
  border: none;
  border-radius: var(--radius);
  color: var(--navy);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 0;
  padding: .5rem .75rem
}

.cta {
  background-color: var(--teal) !important;
  color: var(--off-white) !important
}

.cta-red {
  background-color: var(--orange) !important;
  color: var(--off-white) !important
}

.cta-blue {
  background-color: var(--navy) !important;
  color: var(--off-white) !important
}

.cta:not(.disabled):hover {
  background-color: var(--dark-teal) !important;
  box-shadow: var(--shadow-md);
  cursor: pointer;
  transform: translateY(-1px)
}

.cta-red:not(.disabled):hover {
  background-color: var(--red) !important;
  box-shadow: var(--shadow-md);
  cursor: pointer;
  transform: translateY(-1px)
}

.cta-blue:not(.disabled):hover {
  background-color: var(--blue) !important;
  box-shadow: var(--shadow-md);
  cursor: pointer;
  transform: translateY(-1px)
}

.cta:not(.disabled):active {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px)
}

.cta-red:not(.disabled):active {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px)
}

.cta-blue:not(.disabled):active {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.4);
  transform: translateY(1px)
}

input:hover,
button:not(.disabled):hover {
  box-shadow: 0 0 1px hsla(0, 0%, 0%, 0.6), 0 1px 2px hsla(0, 0%, 0%, 0.2)
}

button:disabled {
  box-shadow: none;
  color: var(--grey) !important;
  cursor: not-allowed !important
}

#content h1 {
  align-items: flex-start;
  display: flex;
  gap: 1rem
}

#content h1 form {
  align-items: center;
  display: flex;
  margin-top: .25rem
}

#content h1 form button {
  box-shadow: none;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0
}

#content h1 form button[value="true"] {
  color: var(--grey)
}

.sr-only {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

#root {
  display: flex;
  height: 100%;
  width: 100%
}

#root-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%
}

#main-container {
  display: flex;
  flex-direction: row;
  height: calc(100% - 4rem);
  width: 100%
}

#topbar {
  align-items: center;
  background: linear-gradient(90deg, var(--navy), var(--teal), var(--navy)) 0 0 / var(--bg-size) 100%;
  box-shadow: var(--shadow-lg);
  color: #fff;
  display: flex;
  font-family: sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  height: 4rem;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 9999;
  --bg-size: 400%
}

#sidebar {
  backdrop-filter: blur(15px);
  background-color: var(--light-blue);
  border: 1px solid var(--cloud);
  border-bottom: 0;
  display: flex;
  flex-direction: column;
  width: 20vw;
  z-index: 9997
}

.mobile-menu {
  align-items: center;
  background: transparent;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  display: none;
  flex-direction: row;
  font-size: 1rem;
  height: 2rem;
  justify-content: space-between;
  padding: 0;
  position: fixed;
  right: 74px;
  top: 15px;
  width: 10rem;
  z-index: 10000
}

.mobile-menu span {
  cursor: pointer;
  margin: 0 .5rem
}

#sidebar>* {
  padding-left: 2rem;
  padding-right: 2rem
}

#sidebar h1 {
  align-items: center;
  border-top: 1px solid var(--cloud);
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  order: 1;
  padding: 1rem 2rem
}

#sidebar h2 {
  align-items: center;
  border-bottom: 1px solid var(--cloud);
  display: flex;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  margin: 0;
  padding: 1rem 2rem
}

#sidebar h1::before {
  margin-right: .5rem;
  position: relative;
  top: 1px
}

#sidebar>div {
  align-items: center;
  background-color: var(--light-blue);
  border-bottom: 1px solid var(--cloud);
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-bottom: 1rem
}

#search-form {
  position: relative
}

input[type="search"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23999' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
  background-position: .625rem .75rem;
  background-repeat: no-repeat;
  background-size: 1rem;
  border-radius: var(--radius);
  margin-bottom: 1rem;
  padding-left: 2rem;
  position: relative;
  width: 100%
}

#sidebar div button {
  border-radius: var(--radius);
  margin-bottom: 1rem;
  width: 100%
}

#sidebar nav {
  flex: 1;
  margin-top: 1rem;
  overflow: auto
}

#sidebar nav span {
  align-items: center;
  border-radius: var(--small);
  color: inherit;
  display: flex;
  font-weight: 600;
  gap: 1rem;
  justify-content: space-between;
  overflow: hidden;
  padding: .5rem;
  text-decoration: none;
  white-space: pre
}

#sidebar nav span:hover {
  background-color: #d2ecec;
  border-radius: var(--radius);
  cursor: pointer
}

#sidebar nav span.active {
  background-color: #d2ecec;
  border-radius: var(--radius)
}

.billing {
  flex: 1
}

.billing .heading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem
}

.billing .heading p {
  max-width: 60vw !important
}

.billing .heading h1 {
  color: var(--navy)
}

.emphasize {
  color: var(--navy);
  font-weight: 600
}

.link {
  color: var(--navy);
  cursor: pointer;
  font-weight: 600;
  text-decoration: none
}

.details {
  flex: 1;
  max-width: 70vw !important;
  padding: .5rem 2rem;
  width: 100%
}

#detail.loading {
  opacity: .25;
  transition: opacity 200ms;
  transition-delay: 200ms
}

.content {
  display: flex;
  height: 100%;
  overflow-y: scroll;
  width: 100%
}

.content-col {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  width: 100%
}

.content h1 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 1.2;
  margin: 0
}

.content h1+p {
  margin: 0
}

.content h1+p+p {
  white-space: break-spaces
}

.content h1:focus {
  color: hsl(224, 98%, 58%);
  outline: none
}

.content a[href*="tel"] {
  color: #3992ff;
  display: flex;
  font-size: 1.5rem;
  text-decoration: none
}

.content a[href*="tel"]:hover {
  text-decoration: underline
}

.content h1~div {
  display: flex;
  gap: .5rem;
  margin: 1rem 0
}

#error-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%
}

#error-page h1 {
  font-size: 3rem;
  font-weight: 400;
  text-shadow: 0 0 10px #00000080
}

#error-page i {
  color: var(--orange);
  font-size: 2rem;
  text-shadow: 0 0 10px #ef5d60
}

.error-message {
  background-color: var(--glass);
  border-radius: var(--radius);
  box-shadow: 2px 2px 5px 0 #0003;
  color: var(--orange);
  font-size: 1.4rem;
  margin-top: 2rem;
  padding: .5rem
}

.form-error {
  color: var(--orange);
  margin-top: 1rem;
  display: block
}

.form-error-hidden {
  display: none;
}

#signup {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 100%
}

.signup-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.signup-form input {
  margin-bottom: 1rem
}

.signup-form button {
  width: 12.6rem
}

#signup input,
#signup button,
#signup #usertype {
  border-radius: var(--radius);
  margin-right: 1rem
}

#signup p {
  color: var(--dark-grey);
  font-size: 1.2rem;
  margin-bottom: 2rem;
  width: 60vw
}

#signup h2, #reset h2 {
  color: var(--navy);
  font-weight: 600 !important;
  max-width: 70vw !important;
  text-align: center
}

#signup h3 {
  color: var(--dark-grey);
  font-weight: 500 !important;
  max-width: 60vw !important;
  text-align: center
}

#reset {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%
}

.reset-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.reset-form .text {
  width: 20rem;
  margin-bottom: 1rem
}

ion-icon.ribbon {
  height: 32px;
  margin-left: -5px;
  margin-right: -4px;
  position: relative;
  top: 12px;
  width: 32px
}

.logo {
  cursor: default;
  font-size: 2rem;
  user-select: none
}

.trill {
  color: var(--navy);
  font-size: 5rem;
  margin-bottom: 2rem;
  text-shadow: 0 0 10px #0003
}

ion-icon.signup-logo {
  height: 64px;
  margin-left: -.625rem;
  margin-right: -.5rem;
  position: relative;
  top: 1.125rem;
  width: 64px
}

.robo-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%
}

.robot-greeting {
  color: var(--navy);
  font-size: 3rem !important;
  margin-bottom: -5rem !important
}

.robot-message {
  color: var(--navy);
  font-size: 3rem !important;
  margin-top: -5rem !important;
  text-align: center
}

.robot-whisper {
  color: var(--navy);
  font-size: 1rem !important;
  font-weight: 300 !important;
  margin-bottom: -1.5rem !important
}

.cute-robot-v1 {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center
}

.cute-robot-v1 * {
  box-sizing: border-box
}

.cute-robot-v1 .circle-bg {
  background: var(--purple);
  border: .5rem solid var(--navy);
  border-radius: 100%;
  height: 15.625rem;
  position: relative;
  width: 15.625rem
}

.cute-robot-v1 .circle-bg:after {
  border-bottom: 10px solid var(--navy);
  border-radius: 100%;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: .5rem;
  width: 100%;
  z-index: 2
}

.cute-robot-v1 .circle-bg:hover .robot-face {
  top: 40px !important
}

.cute-robot-v1 .circle-bg:hover .eyes {
  top: 30px !important
}

.cute-robot-v1 .circle-bg:hover .mouth {
  background: var(--navy);
  border: none;
  clip-path: polygon(0% 30%, 100% 30%, 100% 100%, 0% 100%)
}

.cute-robot-v1 .circle-bg:hover .mouth:after {
  background: #f9bbbb;
  border-radius: 100%;
  bottom: -30%;
  content: "";
  height: 80%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 80%
}

.cute-robot-v1 .circle-bg .robot-head {
  background: #fff;
  border: .5rem solid var(--navy);
  border-radius: 85px/ 60px;
  height: 200px;
  left: 50%;
  position: absolute;
  top: -20px;
  transform: translateX(-50%);
  width: 200px;
  z-index: 4
}

.cute-robot-v1 .circle-bg .robot-head:after {
  background: var(--navy);
  content: "";
  height: 30px;
  left: 50%;
  position: absolute;
  top: -30px;
  transform: translateX(-50%);
  width: 10px
}

.cute-robot-v1 .circle-bg .robot-head:before {
  background: var(--teal);
  border: .5rem solid var(--navy);
  border-radius: 100%;
  content: "";
  height: 2rem;
  left: 50%;
  position: absolute;
  top: -3.8rem;
  transform: translateX(-50%);
  width: 2rem
}

.cute-robot-v1 .circle-bg:hover .robot-head:before {
  background: var(--green);
  border: .5rem solid var(--navy);
  border-radius: 100%;
  content: "";
  height: 2rem;
  left: 50%;
  position: absolute;
  top: -3.8rem;
  transform: translateX(-50%);
  width: 2rem
}

.cute-robot-v1 .circle-bg .robot-head .robot-face {
  background: var(--cloud);
  border: .5rem solid var(--navy);
  border-radius: 45px;
  height: 120px;
  left: 50%;
  position: absolute;
  top: 45px;
  transform: translateX(-50%);
  transition: all 200ms;
  width: 160px
}

.cute-robot-v1 .circle-bg .robot-head .robot-face .eyes {
  background: var(--navy);
  border-radius: 100%;
  height: 1.25rem;
  position: absolute;
  top: 40px;
  transition: all 200ms;
  width: 1.25rem
}

.cute-robot-v1 .circle-bg .robot-head .robot-face .eyes.left {
  left: 30px
}

.cute-robot-v1 .circle-bg .robot-head .robot-face .eyes.right {
  right: 30px
}

.cute-robot-v1 .circle-bg .robot-head .robot-face .mouth {
  border: .5rem solid transparent;
  border-bottom-color: var(--navy);
  border-radius: 100%;
  height: 45px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50px;
  transform: translateX(-50%);
  width: 45px
}

.cute-robot-v1 .circle-bg .robot-ear {
  background: #fff;
  border: .5rem solid var(--navy);
  border-radius: 100%;
  height: 100px;
  position: absolute;
  top: 30px;
  width: 100px;
  z-index: 3
}

.cute-robot-v1 .circle-bg .robot-ear.left {
  left: -20px
}

.cute-robot-v1 .circle-bg .robot-ear.right {
  right: -20px
}

.cute-robot-v1 .circle-bg .robot-body {
  background: #fff;
  border: .5rem solid var(--navy);
  border-radius: 25px 25px 30px 30px;
  bottom: -5px;
  height: 50px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 100px
}

.feedback h1 {
  color: var(--navy)
}

.feedback h1,
textarea {
  margin-bottom: 1rem
}

.feedback button {
  float: right
}

.settings h1 {
  color: var(--navy)
}

.right {
  float: right;
}

@media (max-width: 1000px) {
  #sidebar {
    width: 30vw
  }

  #sidebar>* {
    padding: 0 .25rem
  }

  #sidebar h1,
  #sidebar h2 {
    padding: .25rem
  }

  .mobile-menu {
    display: flex
  }

  .logo {
    display: none !important
  }

  .details,
  .timeline-panel,
  .triage,
  .robo-container,
  .feedback {
    max-width: 60vw !important;
    width: 60vw !important
  }

  .robot-message {
    color: var(--navy);
    font-size: 3rem !important;
    text-align: center
  }

  .billing .heading p {
    max-width: 90vw !important
  }

  #signup h2 {
    font-size: 1.2rem
  }

  #signup h3 {
    font-size: 1rem;
    max-width: 80vw !important
  }
}

.mobile-only {
  display: none
}

.desktop-only {
  display: block
}

@media (max-width: 600px) {
  .mobile-only {
    display: block
  }

  .signup-form,
  .desktop-only,
  .error-message {
    display: none
  }
}

.block-events.e-schedule .template-wrap {
  width: 100%
}

.block-events.e-schedule .e-vertical-view .e-resource-cells {
  height: 58px
}

.block-events.e-schedule .e-timeline-view .e-resource-left-td,
.block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 170px
}
/* cell width shit */
.block-events.e-schedule .e-resource-cells.e-child-node .employee-category,
.block-events.e-schedule .e-resource-cells.e-child-node .employee-name {
  padding: 5px
}

.block-events.e-schedule .e-vertical-view .e-date-header-wrap table col,
.block-events.e-schedule .e-vertical-view .e-content-wrap table col {
  width: 160px;
}

.block-events.e-schedule .e-month-view .e-work-cells,
.block-events.e-schedule .e-month-view .e-date-header-wrap table col {
  width: 160px;
}

.block-events.e-schedule .e-timeline-month-view .e-content-wrap table col,
.block-events.e-schedule .e-timeline-view .e-content-wrap table col {
  width: 100px;
}

/* end cell width */
.block-events.e-schedule .employee-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 45px;
}

.block-events.e-schedule .employee-name {
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.block-events.e-schedule .employee-designation {
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

/* .block-events.e-schedule .template-wrap .employee-image.alice {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png');
}

.block-events.e-schedule .template-wrap .employee-image.robert {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robert.png');
}

.block-events.e-schedule .template-wrap .employee-image.robson {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/robson.png');
}

.block-events.e-schedule .template-wrap .employee-image.laura {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/laura.png');
}

.block-events.e-schedule .template-wrap .employee-image.nancy {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/nancy.png');
}

.block-events.e-schedule .template-wrap .employee-image.margaret {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/margaret.png');
} */

#_recurrence_editor>div>div.e-input-wrapper.e-form-left {
  display: none
}

#EditForm>div>div.e-all-day-time-zone-row {
  display: none
}

#EditForm>div>div.e-title-location-row {
  display: none
}

/* css for agenda provider cell width */
#main-container>div.content>div>div.e-table-container>div>table>tbody>tr>td>div>table>tbody>tr>td.e-resource-column {
  width: 200px
}

.e-schedule-event-tooltip .tooltip-wrap .content-area {
  padding-left: 6px;
  font-size: 12px;
  margin: 0px;
}

.e-schedule-event-tooltip .tooltip-wrap .event-name {
  font-weight: 700;
  font-size: 14px;
}