.timeline--wrapper {
  width: calc(100% - 1.6rem);
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline {
  width: 100%;
  max-width: 800px;
  padding: 3rem 0 0 0;
  position: relative;
  margin-left: 1rem;
  margin-right: 1rem;
}

.timeline:before {
  content: "";
  position: absolute;
  top: 0px;
  left: calc(33% + 4px);
  bottom: 0px;
  width: 0px;
  border: 4px solid;
}

.timeline:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 800px) {
  /* For mobile phones: */
  .timeline:before {
    left: calc(1% + 6px);
  }
}