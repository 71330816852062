.triage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  height: 10rem;
  max-width: 64vw
}

.input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: space-between;
  flex-wrap: wrap
}

.input-container input {
  flex: 1 0 20%;
  margin: 5px
}

@media (max-width: 1200px) {
  .input-container input {
    flex: 1 0 40%
  }

  .triage {
    height: 16rem
  }
}

@media (max-width: 800px) {
  .input-container input {
    flex: 1 0 60%
  }

  .triage {
    height: 20rem
  }
}

.triage input {
  margin-right: .5rem;
  width: 100%;
  border-radius: var(--radius);
  background-color: #fff;
  box-shadow: var(--shadow)
}

.triage button {
  width: 20%;
  align-self: flex-end;
  margin-right: .5rem;
  border-radius: var(--radius);
  box-shadow: var(--shadow)
}

.new-visit {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center
}

.new-visit button {
  width: 20vw;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  margin: 1rem;
  margin-right: 18rem
}

textarea {
  width: 100%;
  height: 20rem;
  border: none;
  resize: none;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  padding: 1rem;
  box-sizing: border-box;
  outline: none;
  background-color: var(--glass) !important;
  border-radius: var(--radius) !important
}

.triage .voice-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: .4rem
}

.wave {
  height: 40px;
  width: 40px;
  margin-right: 1rem
}

.col {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.voice-controls ion-icon {
  color: var(--green);
  cursor: pointer;
  margin-right: 1rem;
  height: 100px;
  width: 100px;
  border-radius: 80px
}

.voice-controls ion-icon:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
  background-color: var(--glass)
}

.voice-controls ion-icon:active {
  transform: translateY(1px);
  color: var(--teal);
  box-shadow: var(--shadow-md-inset);
  background-color: var(--cloud)
}

.voice-controls>span {
  color: var(--grey);
  margin-right: 1rem
}

.note-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: .5rem
}

.note-type-select {
  width: 20rem;
  margin-left: .5rem
}