@import "../../constants.css";

.patients {
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: scroll
}

.timeline-panel {
  flex: 1;
  flex-direction: column;
  height: 78%;
  width: 68%;
  overflow-y: scroll;
  border-radius: var(--radius);
  position: fixed
}

.timeline-panel h2 {
  margin-left: 1.4em;
  font-weight: 400;
  color: var(--dark-grey)
}

.patient-panel p {
  margin: .5rem 0;
  font-size: 2rem;
  font-weight: 400;
  line-height: 1rem
}

.patient-panel span {
  font-weight: 300
}

.patient-panel img {
  float: left;
  margin-right: 2rem;
  border-radius: 3rem;
  object-fit: cover;
  border: 2px solid #fff;
  box-shadow: 2px 2px 5px 0 #0003;
  -webkit-box-shadow: 2px 2px 5px 0 #0003;
  -moz-box-shadow: 2px 2px 5px 0 #0003
}

.patient-panel h1 {
  font-weight: 300 !important
}

.card {
  background-color: #fff;
  padding: 1.5rem;
  box-shadow: var(--shadow-xl);
  border-radius: var(--radius);
  margin-top: -.7rem;
  cursor: pointer;
  font-weight: 300;
  border-bottom-color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: var(--shadow-lg);
  border-bottom-style: solid;
  border-bottom-width: 3px
}

.card:hover {
  border-bottom-color: var(--blue)
}

.card.active {
  border-bottom-color: var(--blue);
  animation: bounce-in .4s;
  cursor: default
}

li {
  color: var(--navy)
}

p h4 {
  margin-top: 1rem !important
}

.allergies {
  font-weight: 600 !important;
  color: var(--orange)
}

.edit {
  color: var(--navy);
  float: right;
  cursor: pointer;
  font-weight: 600
}

.edit:hover {
  color: var(--blue)
}

.meds {
  font-weight: 600;
  color: var(--navy);
  text-transform: uppercase
}

.refs {
  color: var(--navy);
  cursor: pointer;
  font-weight: 600
}

.refs:hover {
  color: var(--blue)
}

.timeline-card-section {
  padding: .25rem;
  border-bottom: 1px solid var(--cloud);
  margin-bottom: 1rem
}

small ion-icon {
  transform: translateY(4px)
}

.modal {
  margin-top: 2rem;
  margin-bottom: 2rem
}

form button {
  margin-right: 1rem
}

.edit-buttons {
  float: right
}

.aibold {
  font-weight: 600;
  /* color: var(--navy); */
  font-size: 0.9rem;
  text-transform: uppercase
}

.wordwrap {
  overflow-wrap: break-word;
}