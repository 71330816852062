.new-patient-header {
  color: var(--dark-grey);
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 50vw
}

.new-patient-header h1 {
  color: var(--dark-grey);
  margin-bottom: 1rem
}

.new-patient-form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  max-width: 64vw
}

.new-patient-form > div {
  display: flex;
  flex-direction: column;
  margin-right: 1rem
}

.new-patient-form input {
  margin-bottom: 1rem;
  width: 20rem
}

.new-patient-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem
}

.new-patient-actions button {
  margin-right: 1rem
}