@import "../../constants.css";

.loader-container {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 100% !important;
  align-items: center
}

.loader-container-small {
  display: flex;
  flex-direction: column;
  height: 6rem;
  width: 100% !important;
  align-items: center;
  padding-top: 4rem
}

.loader-container .loader-panel {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row
}

.spinner {
  position: relative;
  top: -70px;
  width: 100px;
  transform-origin: 50% 50%;
  transform: rotate(30deg) scale(0.5, 1)
}

.bar-outer {
  transform-origin: 5px 5px;
  width: 100px;
  position: absolute;
  top: 0;
  left: 0
}

.bar-outer.right {
  width: 94px
}

.bar-outer.right .bar {
  background: var(--navy)
}

.bar-outer.down-right {
  transform: rotate(20deg)
}

.bar-outer.down-right .bar {
  background: var(--navy)
}

.bar-outer.up-right {
  transform: rotate(-20deg)
}

.bar-outer.up-right .bar {
  background: var(--purple)
}

.bar-outer.up-left {
  transform: rotate(20deg)
}

.bar-outer.up-left .bar {
  background: var(--navy);
  animation-name: ribbon-left
}

.bar-outer.down-left {
  transform: rotate(-20deg)
}

.bar-outer.down-left .bar {
  background: var(--purple);
  animation-name: ribbon-left
}

.bar-outer .bar {
  width: 100px;
  height: 10px;
  border-radius: 5px;
  background: gray;
  position: absolute;
  top: 0;
  opacity: 0;
  animation: ribbon 1.2s linear 0s infinite
}

.top.b1 {
  top: 0
}

.top.b1 .bar {
  animation-delay: 0s
}

.b2 {
  top: 31px
}

.b2 .bar {
  animation-delay: .15s
}

.b3 {
  top: 31px
}

.b3 .bar {
  animation-delay: .3s
}

.b4 {
  top: 93px
}

.b4 .bar {
  animation-delay: .45s
}

.bottom.b5 {
  top: 93px
}

.bottom.b5 .bar {
  animation-delay: .6s
}

.b6 {
  top: 62px
}

.b6 .bar {
  animation-delay: .75s
}

.b7 {
  top: 62px
}

.b7 .bar {
  background: red;
  animation-delay: .9s
}

.b8 {
  top: 0
}

.b8 .bar {
  animation-delay: 1.05s
}

@keyframes ribbon {
  from {
    opacity: 1;
    left: 0;
    right: auto;
    width: 0
  }

  11% {
    width: 100%;
    left: 0
  }

  12% {
    width: 100%;
    left: auto;
    right: 0
  }

  35% {
    width: 100%
  }

  46% {
    left: auto;
    right: 0;
    width: 0;
    opacity: 0
  }

  to {
    left: 0;
    right: auto;
    width: 0;
    opacity: 0
  }
}

@keyframes ribbon-left {
  from {
    opacity: 1;
    right: 0;
    width: 0
  }

  11% {
    width: 100%;
    right: 0
  }

  12% {
    width: 100%
  }

  35% {
    width: 100%
  }

  46% {
    left: 0;
    right: auto;
    width: 0
  }

  to {
    left: 0;
    right: auto;
    width: 0;
    opacity: 1
  }
}