:root {
  --navy: #4959ae;
  --blue: #3b82f6;
  --light-blue: #eff6ff;
  --dark-teal: #2e7e7e;
  --teal: #21a0a0;
  --green: #4dd184;
  --yellow: #f0c419;
  --orange: #ce596d;
  --red: #ef5d60;
  --pink: #fbcfbd;
  --purple: #7f88b6;
  --off-white: #f5f0f6;
  --cloud: #e3e3e3;
  --grey: #8797b2;
  --dark-grey: #374151;
  --black: #241C15;
  --glass: rgba(255, 255, 255, 0.45);
  --glass-border: rgba(255, 255, 255, .25);
  --radius: 0.375rem;

  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-inset: inset 0 1px 3px 0 rgb(0 0 0 / 0.1), inset 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-md-inset: inset 0 4px 6px -1px rgb(0 0 0 / 0.1), inset 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);

  --toastify-color-success: var(--green) !important;
  --toastify-color-error: var(--red) !important;
}
