.footer {
  font-size: .8rem;
  background-color: var(--off-white);
  width: 100vw;
  /* padding: 0 10rem 0 10rem; */
  border-top: 1px solid var(--cloud);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.footer span {
  margin: 0 1rem 0 1rem
}

.footer a {
  color: var(--navy);
  text-decoration: none;
  margin: 0 1rem 0 1rem
}

@media (max-width: 600px) {
  .footer {
    display: none
  }
}